import React, { useRef, useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from "../assets/logo.png";

function PayStub() {
  const [password, setPassword] = useState('');
  const [showForm, setShowForm] = useState(false);

  const passwordRef = useRef(null);

  const stateRef = useRef(null);
  const emailRef = useRef(null);
  const companyNameRef = useRef(null);
  const companyAddressRef = useRef(null);
  const companyCityRef = useRef(null);
  const companyStateRef = useRef(null);
  const companyZipRef = useRef(null);
  const employeeNameRef = useRef(null);
  const employeeIdRef = useRef(null);
  const employeeSSNRef = useRef(null);
  const employeeMaritalStatusRef = useRef(null);
  const employeeCityRef = useRef(null);
  const employeeStateRef = useRef(null);
  const employeeZipRef = useRef(null);
  const paymentTypeRef = useRef(null);
  const paymentScheduleRef = useRef(null);
  const hourlyRateRef = useRef(null);
  const payDateRef = useRef(null);
  const totalHoursRef = useRef(null);

  const checkPassword = () => {
    if (passwordRef.current.value === 'Acp1120') {
      setShowForm(true);
    } else {
      alert('Incorrect password!');
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const img = new Image();
    img.src = Logo;
  
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    };
  
    const formattedPayDate = formatDate(payDateRef.current.value);
  
    doc.addImage(img, 'PNG', 10, 10, 50, 15);
    doc.setFontSize(18);
    doc.text('Statement of Earnings & Deductions', 105, 40, null, null, 'center');
  
    doc.setFontSize(12);
    doc.autoTable({
      startY: 45,
      head: [['Details']],
      body: [
        ['Company Name: ' + companyNameRef.current.value],
        ['Address: ' + companyAddressRef.current.value],
        ['City: ' + companyCityRef.current.value],
        ['State: ' + companyStateRef.current.value],
        ['Zip: ' + companyZipRef.current.value]
      ]
    });
  
    doc.text('Employee Information', 10, doc.lastAutoTable.finalY + 10);
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 15,
      head: [['Details']],
      body: [
        ['Employee Name: ' + employeeNameRef.current.value],
        ['Employee Id: ' + employeeIdRef.current.value],
        ['SSN: ' + employeeSSNRef.current.value],
        ['Marital Status: ' + employeeMaritalStatusRef.current.value],
        ['City: ' + employeeCityRef.current.value],
        ['State: ' + employeeStateRef.current.value],
        ['Zip: ' + employeeZipRef.current.value]
      ]
    });
  
    doc.text('Payment Details', 10, doc.lastAutoTable.finalY + 10);
    const hourlyRate = parseFloat(hourlyRateRef.current.value);
    const totalHours = parseFloat(totalHoursRef.current.value);
    const totalSalary = hourlyRate * totalHours;
  
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 15,
      head: [['Details']],
      body: [
        ['Payment Type: ' + paymentTypeRef.current.value],
        ['Payment Schedule: ' + paymentScheduleRef.current.value],
        ['Hourly Rate: ' + hourlyRate.toFixed(2)],
        ['Pay Date: ' + formattedPayDate],
        ['Total Hours: ' + totalHours.toFixed(2)],
        ['Total Salary: ' + totalSalary.toFixed(2)]
      ]
    });
  
    doc.save('paystub.pdf');
  };
  
  
  return (
    <>
      {!showForm ? (
        <div className="d-flex justify-content-center flex-column gap-4 align-items-center" style={{ height: '100vh' }}>
          <h1>Enter Your Password:</h1>
          <input type="password" ref={passwordRef} />
          <button onClick={checkPassword}>Enter</button>
        </div>
      ) : (
        <div className='container'>
          <div className="row">
            <div className="col-lg-6">
              <h1>Create Employee Paystub</h1>
              <p>Simply enter your details. The PDF preview automatically updates and calculates all your details. You can choose multiple pay periods and customize the design of it.</p>
            </div>
            <form>
              <div className="d-flex gap-4">
                <div className="d-flex flex-column">
                  <label>State</label>
                  <select className='p-2' ref={stateRef}>
                    <option>Select State:</option>
                    <option>Massachusetts</option>
                  </select>
                </div>
                <div className="d-flex flex-column">
                  <label>Email</label>
                  <input type='email' name='email' placeholder='Email (required)' className='p-2' ref={emailRef} />
                </div>
              </div>
              <h3 className='mt-3'>Company Info:</h3>
              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex flex-column">
                    <input type='text' name='companyName' placeholder='Company Name (required)' className='p-2 w-75' ref={companyNameRef} />
                    <input type='text' name='address' placeholder='Street Address (required)' className='p-2 mt-4 w-75' ref={companyAddressRef} />
                    <div className="row">
                      <div className="col-lg-4">
                        <input type='text' name='city' placeholder='City' className='p-2 mt-4 w-100' ref={companyCityRef} />
                      </div>
                      <div className="col-lg-2">
                        <select className='p-2 mt-4' ref={companyStateRef}>
                          <option>Select State:</option>
                          <option>Massachusetts</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mx-5">
                        <input type='text' name='zip' placeholder='Zip' className='p-2 mt-4 w-100' ref={companyZipRef} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h3 className='mt-3'>Employee Info:</h3>
              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex flex-column">
                    <input type='text' name='employeeName' placeholder='Employee Name (required)' className='p-2 w-75' ref={employeeNameRef} />

                    <div className="row w-100">
                      <div className="col-lg-4">
                        <input type='text' name='employeeId' placeholder='Employee Id' className='p-2 mt-4 w-100' ref={employeeIdRef} />
                      </div>
                      <div className="col-lg-3">
                        <input type='text' name='ssn' placeholder='Last 4 of SSN' className='p-2 mt-4 w-100' ref={employeeSSNRef} />
                      </div>
                      <div className="col-lg-1">
                        <select className='p-2 mt-4' ref={employeeMaritalStatusRef}>
                          <option>Marital Status:</option>
                          <option>Single</option>
                          <option>Married</option>
                        </select>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 w-75">
                          <input type='text' name='employeeCity' placeholder='Employee City' className='p-2 mt-4 w-100' ref={employeeCityRef} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <input type='text' name='city' placeholder='City' className='p-2 mt-4 w-100' ref={employeeCityRef} />
                      </div>
                      <div className="col-lg-2">
                        <select className='p-2 mt-4' ref={employeeStateRef}>
                          <option>Select State:</option>
                          <option>Massachusetts</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mx-5">
                        <input type='text' name='zip' placeholder='Zip' className='p-2 mt-4 w-100' ref={employeeZipRef} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h3>How are you paid?</h3>
              <div className="row">
                <div className="col-lg-2">
                  <div className="d-flex flex-column">
                    <label>Hourly Rate </label>
                    <select ref={paymentTypeRef}>
                      <option>Hourly</option>
                      <option>Salary</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="d-flex flex-column">
                    <label>Pay Schedule</label>
                    <select ref={paymentScheduleRef}>
                      <option>Weekly</option>
                      <option>Monthly</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="d-flex flex-column">
                    <label>Hourly Rate </label>
                    <input type='number' ref={hourlyRateRef} />
                  </div>
                </div>
              </div>
              <h3>Current Paystub</h3>
              <div className="row">
                <div className="col-lg-3">
                  <label>Pay Date</label>
                  <input type='date' name='payDate' className='p-2 mt-4 w-100' ref={payDateRef} />
                </div>
                <div className="col-lg-3">
                  <label>Total Hours</label>
                  <input type='number' name='totalHours' placeholder='Total Hours' className='p-2 mt-4 w-100' ref={totalHoursRef} />
                </div>
              </div>
              <button type="button" onClick={generatePDF} className="btn btn-primary mt-3">Generate PDF</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default PayStub;
